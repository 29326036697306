import React from 'react'

export const ICONAngleBottom = () => <svg data-prefix='far' data-icon='angle-down' className='svg-inline--fa fa-angle-down fa-w-10'xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'> <path fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/></svg>
export const ICONAngleRight = () => <svg data-prefix='fas' data-icon='chevron-right' className='svg-inline--fa fa-chevron-right fa-w-10'xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'> <path fill='currentColor' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'/></svg>
export const ICONAngleLeft = () => <svg data-prefix='fas' data-icon='chevron-left' className='svg-inline--fa fa-chevron-left fa-w-10'xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'> <path fill='currentColor' d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'/></svg>
export const ICONLinkedin = () => <svg data-prefix='fab' data-icon='linkedin-in' className='svg-inline--fa fa-linkedin-in fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z'/></svg>
export const ICONFacebook = () => <svg data-prefix='fab' data-icon='facebook-f' className='svg-inline--fa fa-facebook-f fa-w-9'
xmlns='http://www.w3.org/2000/svg' viewBox='0 0 264 512'><path fill='currentColor' d='M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229'/></svg>
export const ICONTwitter = () => <svg data-prefix='fab' data-icon='twitter' className='svg-inline--fa fa-twitter fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'/></svg>
export const ICONPlay = () => <svg height="33px" width="28px" version="1.1" viewBox="0 0 28 33"><defs/><g id="Page-1" fill="none" stroke="none" strokeWidth="1"><g id="careers" fill="#23558D" transform="translate(-711.000000, -1327.000000)"><g id="Bitmap-Copy-2" transform="translate(335.000000, 1137.000000)"><g id="play" transform="translate(340.000000, 160.000000)"><polygon id="Shape" points="63.5226306 46.4651171 36.2945492 62.903893 36.2945492 30.026209"/></g></g></g></g></svg>
export const ICONDownload = () => <svg width='23' height='23' viewBox='0 0 23 23' xmlns='http://www.w3.org/2000/svg'><g id='Page-1' fill='none' fillRule='evenodd' fillOpacity='0.8'><g id='single-quarry' transform='translate(-1282 -2959)' fill='#151515' fillRule='nonzero'><g id='icon-download-copy-2' transform='translate(1282 2959)'><polygon id='Shape' points='10.6055556 0 11.8833333 0 11.8833333 10.9888889 10.6055556 10.9888889'/><path d='M11.2444444,16.6111111 L8.43333333,8.81666667 L14.0555556,8.81666667 L11.2444444,16.6111111 Z M22.4888889,23 L0,23 L0,16.6111111 L1.27777778,16.6111111 L1.27777778,21.7222222 L21.2111111,21.7222222 L21.2111111,16.6111111 L22.4888889,16.6111111 L22.4888889,23 Z' id='Shape' /></g></g></g></svg>
export const ICONMapMarker = () => <svg width='43' height='61' viewBox='0 0 43 61' xmlns='http://www.w3.org/2000/svg'> <g id='Page-1' fill='none' fillRule='evenodd'> <g id='contact-' transform='translate(-655 -984)' fill='#F58C1F' fillRule='nonzero'> <g id='Screen-Shot-2018-07-24-at-3.59.18-pm' transform='translate(0 787)'> <g id='maps-and-flags' transform='translate(655 197)'> <path d='M21.5,0 C9.64483597,0 0,9.7479025 0,21.7296359 C0,36.5993415 19.2403938,58.4289384 20.0595706,59.3509708 C20.3181004,59.6419914 20.802641,60.1916524 21.5131924,60.9999538 C22.210717,60.18653 22.6864627,59.636869 22.9404294,59.3509708 C23.7596062,58.4289384 43,36.5993415 43,21.7296359 C42.9997681,9.7479025 33.3550481,0 21.5,0 Z M21.5,32.6624226 C15.5353724,32.6624226 10.6829237,27.7580026 10.6829237,21.7296359 C10.6829237,15.7012691 15.5354884,10.7969663 21.5,10.7969663 C27.4645116,10.7969663 32.3169603,15.7013863 32.3169603,21.729753 C32.3169603,27.7581198 27.4645116,32.6624226 21.5,32.6624226 Z' id='Shape'/> </g> </g> </g> </g></svg>
